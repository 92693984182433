const storageKeys = {
  accessToken: 'accessToken'
}

export function setAccessToken(accessToken) {
  localStorage.setItem(storageKeys.accessToken, accessToken)
}

export function getAccessToken() {
  return localStorage.getItem(storageKeys.accessToken)
}

export function isAuthenticated() {
  const accessToken = localStorage.getItem(storageKeys.accessToken)
  if (accessToken === null) return false

  const base64payload = accessToken.split('.')[0]
  const payload = JSON.parse(window.atob(base64payload))

  return payload?.username !== null && payload?.exp > new Date()
}

export function getAccessInfo() {
  const accessToken = localStorage.getItem(storageKeys.accessToken)
  if (accessToken === null) return false

  const base64payload = accessToken.split('.')[0]
  const payload = JSON.parse(window.atob(base64payload))

  return payload
}

export function logout() {
  localStorage.removeItem(storageKeys.accessToken)
  window.location.pathname = '/'
}
