import React, { useEffect, useState } from 'react'
import services from '../services'
import { Container, TextField, Typography, Divider, Button } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import * as Auth from '../helpers/auth'
/**
 * check if user and assignment has team.
 * if not, allow user to create team, but also select other users.
 *
 */
export default function CreateTeam({ classId, assignmentId, onSave }) {
  const [teamName, setTeamName] = useState([])
  const [studentList, setStudentList] = useState([])
  const [teamList, setTeamList] = useState([])
  const { username } = Auth.getAccessInfo()
  const [errors, setErrors] = useState({ teamName: '' })
  useEffect(() => {
    //get list of current users in class
    async function go() {
      const students = await services.students.getAll({ classId })
      setStudentList(students.data)
      setTeamList([username])
    }
    go()
  }, [classId, assignmentId])

  async function saveTeam() {
    const errors = { some: false, teamName: '' }
    if (teamName === '') {
      errors.some = true
      errors.teamName = 'Name is required'
    }
    setErrors(errors)

    if (!errors.some) {
      await services.teams.post({ classId, assignmentId, name: teamName, students: teamList })
      onSave()
    }
  }

  return (
    <Container>
      <Typography variant="h5">Create Your Team</Typography>
      <Divider />
      <br />
      <TextField
        label="Team Name"
        value={teamName}
        onChange={(e) => setTeamName(e.target.value)}
        error={errors.teamName !== ''}
        helperText={errors.teamName}
      />
      <br />
      <br />
      <Autocomplete
        multiple
        disablePortal
        value={teamList}
        onChange={(_, newValue) => {
          setTeamList([...new Set([username, ...newValue])])
        }}
        options={studentList}
        renderInput={(params) => <TextField {...params} variant="outlined" label="Team members" />}
      />
      <br />
      <Button onClick={saveTeam}>Save Team</Button>
    </Container>
  )
}
