import React, { useCallback, useEffect, useState } from 'react'
import CreateTeam from './create-team'
import * as Auth from '../helpers/auth'
import services from '../services'
import { CircularProgress } from '@mui/material'
import CodeEditor from './code-editor'
import MainTemplate from '../templates/mainTemplate'

/**
 * Get current assignment + Team (assignment work). loads CodeEditor.
 * Does current assignment already exist? if not, create team.
 * Create Team:
 *    should allow you to add other users.
 * if
 *
 */
export default function Assignment({ assignmentId }) {
  const { classId } = Auth.getAccessInfo()
  const [loadState, setLoadState] = useState('loading')
  const [team, setTeam] = useState(null)
  const [assignment, setAssignment] = useState(null)

  const getTeam = useCallback(async () => {
    const teamResponse = await services.teams.getOwn({ classId, assignmentId })
    const assignmentResponse = await services.assignments.get({ classId, assignmentId })
    setTeam(teamResponse.data)
    setAssignment(assignmentResponse.data)
    setLoadState('complete')
  }, [assignmentId, classId])

  useEffect(() => {
    getTeam()
  }, [assignmentId, getTeam])

  if (loadState === 'loading') {
    return (
      <MainTemplate>
        <CircularProgress />
      </MainTemplate>
    )
  }
  if (!team) {
    return (
      <MainTemplate>
        <CreateTeam classId={classId} assignmentId={assignmentId} onSave={getTeam} />
      </MainTemplate>
    )
  }
  return (
    <CodeEditor
      project={team}
      assignment={assignment}
      onSave={async ({ html, css, js, md }) =>
        await services.teams.put({
          ...team,
          html,
          css,
          js,
          md
        })
      }
    />
  )
}
