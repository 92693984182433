import { Avatar, Card, CardHeader, CircularProgress, Divider, IconButton, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { red } from '@mui/material/colors'
import { useHistory } from 'react-router-dom'
import services from '../services'
import * as Auth from '../helpers/auth'
/**
 * - Displays current and past assignments
 * - Displays personal projects
 *
 */
export default function Dashboard() {
  const history = useHistory()
  const [state, setState] = useState({ assignments: [], personalProjects: [], status: 'success' })

  useEffect(() => {
    // get assignments
    async function go() {
      const { classId } = Auth.getAccessInfo()
      const response = await services.assignments.getAll({ classId })
      setState({
        assignments: response.data,
        personalProjects: [],
        status: 'success'
      })
    }
    go()
    // get existing personal projects
  }, [])

  switch (state.status) {
    case 'fetching':
      return <CircularProgress />
    case 'error':
      return <Typography variant="h4">An Error Occurred</Typography>
    case 'success':
      return (
        <div>
          <Typography variant="h3">Dashboard</Typography>
          <Divider />
          <br />
          <Typography variant="h5">Assignments</Typography>
          <br />
          {state.assignments.map((x) => (
            <Card key={x.id} style={{ marginBottom: '1rem' }}>
              <CardHeader
                avatar={
                  <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                    {x.id}
                  </Avatar>
                }
                action={
                  <IconButton aria-label="Go" style={{ margin: '4px' }} onClick={() => history.push(`/assignments/${x.id}`)}>
                    <ArrowForwardIosIcon />
                  </IconButton>
                }
                title={x.name}
                subheader={x.shortDescription}
              />
            </Card>
          ))}
        </div>
      )
  }
}
