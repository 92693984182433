import React, { useEffect, useRef, useState } from 'react'
import List from '@mui/material/List'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import CodeIcon from '@mui/icons-material/Code'
import ArticleIcon from '@mui/icons-material/Article'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import InfoIcon from '@mui/icons-material/Info'
import HomeIcon from '@mui/icons-material/Home'
import SmsIcon from '@mui/icons-material/Sms'
import { ReactComponent as CssIcon } from '../assets/css.svg'
import { ReactComponent as JsIcon } from '../assets/js.svg'
import { Divider, ListItemButton } from '@mui/material'
import MonacoEditor from '@monaco-editor/react'
import Markdown from './markdown'
import { useHistory } from 'react-router'
import * as comment from '../helpers/comment'

const files = {
  md: <ArticleIcon height={24} width={24} />,
  html: <CodeIcon />,
  css: <CssIcon height={24} width={24} />,
  js: <JsIcon height={24} width={24} />
}

const language = {
  html: 'html',
  css: 'css',
  js: 'javascript',
  md: 'markdown'
}

/** can pass in a team or an individual project */
export default function CodeEditor({ project, assignment = {}, onSave }) {
  const editorRef = {
    html: useRef(null),
    css: useRef(null),
    js: useRef(null),
    md: useRef(null)
  }

  const history = useHistory()
  const [currentFile, setCurrentFile] = useState('html')
  const [viewMode, setViewMode] = useState(assignment.description ? 'info' : 'code')
  const [srcDocFields, setSrcDocFields] = useState({
    html: '',
    css: '',
    js: '',
    md: ''
  })

  const handleEditorDidMount = (key) => (editor) => {
    editorRef[key].current = editor
  }

  function showResult() {
    if (viewMode === 'code') {
      setSrcDocFields({
        html: editorRef.html.current.getValue(),
        css: editorRef.css.current.getValue(),
        js: editorRef.js.current.getValue(),
        md: editorRef.md.current.getValue()
      })
      onSave?.({
        html: editorRef.html.current.getValue(),
        css: editorRef.css.current.getValue(),
        js: editorRef.js.current.getValue(),
        md: editorRef.md.current.getValue()
      })
    }

    setViewMode(viewMode === 'code' ? 'web' : 'code')
  }

  function handleFileChange(key) {
    setViewMode('code')
    setCurrentFile(key)
  }

  function showAssignment() {
    setViewMode('info')
  }

  function handleComment() {
    comment.popup()
  }

  // handle CTRL+s
  useEffect(() => {
    const keydownHandler = (e) => {
      if (e.keyCode === 83 && (e.ctrlKey || e.metaKey)) {
        editorRef[currentFile].current.getAction('editor.action.formatDocument').run()
        onSave?.({
          html: editorRef.html.current.getValue(),
          css: editorRef.css.current.getValue(),
          js: editorRef.js.current.getValue(),
          md: editorRef.md.current.getValue()
        })
        e.preventDefault()
        return false
      }
    }
    window.addEventListener('keydown', keydownHandler)

    window.onbeforeunload = () => {
      onSave?.({
        html: editorRef.html.current.getValue(),
        css: editorRef.css.current.getValue(),
        js: editorRef.js.current.getValue(),
        md: editorRef.md.current.getValue()
      })
      return
    }
    // unmount
    return () => {
      window.removeEventListener('keydown', keydownHandler)
      window.onbeforeunload = undefined
    }
  }, [currentFile, editorRef, onSave])

  return (
    <div>
      <div
        style={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          position: 'relative'
        }}
      >
        <div
          style={{
            flex: 0,
            width: 120,
            backgroundColor: 'rgb(21,35,45)',
            color: 'white',
            borderRight: '1px solid rgb(25,53,73)'
          }}
        >
          <div style={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'stretch' }}>
            <List style={{ flex: 1, flexGrow: 1 }}>
              {assignment.description && (
                <>
                  <ListItemButton onClick={showAssignment} selected={viewMode === 'info'}>
                    <ListItemAvatar>
                      <InfoIcon />
                    </ListItemAvatar>
                    <ListItemText>Info</ListItemText>
                  </ListItemButton>
                  <Divider />
                </>
              )}
              {Object.keys(files)
                .filter((x) => assignment.hasOutline || x !== 'md')
                .map((key) => (
                  <ListItemButton
                    key={key}
                    selected={key === currentFile && viewMode === 'code'}
                    onClick={() => handleFileChange(key)}
                  >
                    <ListItemAvatar>{files[key]}</ListItemAvatar>
                    <ListItemText>{key === 'md' ? 'Outline' : key}</ListItemText>
                  </ListItemButton>
                ))
                .reduce((a, b) => [a, <Divider key={1} />, b])}
              <Divider />
              <ListItemButton onClick={showResult} selected={viewMode === 'web'}>
                <ListItemAvatar>
                  <PlayArrowIcon />
                </ListItemAvatar>
                <ListItemText>View</ListItemText>
              </ListItemButton>
            </List>
            <List>
              {assignment.description && (
                <ListItemButton onClick={handleComment}>
                  <ListItemAvatar>
                    <SmsIcon />
                  </ListItemAvatar>
                </ListItemButton>
              )}
              <Divider />
              <ListItemButton
                onClick={() => {
                  onSave({
                    html: editorRef.html.current.getValue(),
                    css: editorRef.css.current.getValue(),
                    js: editorRef.js.current.getValue(),
                    md: editorRef.md.current.getValue()
                  })
                  history.push('/dashboard')
                }}
                selected={viewMode === 'web'}
              >
                <ListItemAvatar>
                  <HomeIcon />
                </ListItemAvatar>
                <ListItemText>Home</ListItemText>
              </ListItemButton>
            </List>
          </div>
        </div>
        <div
          style={{
            flex: 1,
            overflow: 'none',
            display: viewMode === 'code' ? 'block' : 'none'
          }}
        >
          {Object.keys(files).map((key) => (
            <div
              key={key}
              style={{
                height: key === currentFile ? '100%' : '0px'
              }}
            >
              <MonacoEditor
                height="100%"
                theme="vs-dark"
                defaultLanguage={language[key]}
                defaultValue={project?.[key]}
                onMount={handleEditorDidMount(key)}
              />
            </div>
          ))}
        </div>
        {viewMode === 'web' && (
          <iframe
            style={{
              flexGrow: 1,
              overflow: 'none',
              backgroundColor: 'white'
            }}
            srcDoc={`<!DOCTYPE html>
<html>
  <style>
    ${srcDocFields.css}
  </style>
  <body>
    ${srcDocFields.html}
    <script>
      ${srcDocFields.js}
    </script>
  </body>
</html>
      `}
          />
        )}
        <Markdown
          style={{
            flex: 1,
            overflow: 'scroll',
            maxHeight: '100vh',
            display: viewMode === 'info' ? 'block' : 'none',
            padding: '2rem'
          }}
          text={assignment.description || 'No Description'}
        />
      </div>
    </div>
  )
}
