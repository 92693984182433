import './App.css'
import CodeEditor from './pages/code-editor'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import Splash from './pages/splash'
import Dashboard from './pages/dashboard'
import MainTemplate from './templates/mainTemplate'
import Markdown from './pages/markdown'
import * as Auth from './helpers/auth'
import Assignment from './pages/assignment'

const darkTheme = createTheme({
  palette: {
    mode: 'dark'
  },
  components: {
    MuiListItemAvatar: {
      styleOverrides: {
        root: {
          minWidth: 35
        }
      }
    }
  }
})

function AuthRoute(props) {
  if (Auth.isAuthenticated()) {
    return <Route {...props}>{props.children}</Route>
  }
  return <Redirect to="/" />
}

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={darkTheme}>
        <BrowserRouter>
          <Switch>
            <Route exact path="/">
              {Auth.isAuthenticated() ? <Redirect to="/dashboard" /> : <Splash />}
            </Route>
            <Route
              exact
              path="/logout"
              render={() => {
                Auth.logout()
                return <Redirect to="/" />
              }}
            />
            <Route path="/editor">
              <CodeEditor
                project={(() => {
                  const files = { html: '', css: '', js: '' }
                  try {
                    const cache = JSON.parse(localStorage.getItem('editor-cache'))
                    files.html = cache.html
                    files.css = cache.css
                    files.js = cache.js
                  } finally {
                    return files
                  }
                })()}
                onSave={async ({ html, css, js }) => {
                  localStorage.setItem('editor-cache', JSON.stringify({ html, css, js }))
                }}
              />
            </Route>
            <Route path="/md">
              <Markdown text={'#Hello Test'} />
            </Route>
            <AuthRoute path="/dashboard">
              <MainTemplate>
                <Dashboard />
              </MainTemplate>
            </AuthRoute>
            <AuthRoute
              path="/assignments/:assignmentId"
              render={({ match }) => {
                return <Assignment assignmentId={match.params.assignmentId} />
              }}
            ></AuthRoute>
            <Redirect to="/" />
          </Switch>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  )
}

export default App
