import React, { useEffect, useState } from 'react'
import { Button, CircularProgress } from '@mui/material'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import * as Auth from '../helpers/auth'
import services from '../services'

const loginStates = {
  checkingIfLoggedInAlready: 'checkingIfLoggedInAlready',
  notLoggedIn: 'notLoggedIn',
  loggingIn: 'loggingIn',
  success: 'success',
  failure: 'failure'
}

export default function Splash() {
  const history = useHistory()
  const [loginState, setLoginState] = useState(loginStates.checkingIfLoggedInAlready)

  const loginClicked = async () => {
    const result = await Swal.fire({
      title: 'Login',
      html:
        'Username: <input id="swal-input1" class="swal2-input">' +
        'Password: <input type="password" id="swal-input2" class="swal2-input">',
      preConfirm: function () {
        return new Promise(function (resolve) {
          resolve([document.getElementById('swal-input1').value, document.getElementById('swal-input2').value])
        })
      },
      onOpen: function () {
        document.getElementById('swal-input1').focus()
      }
    })

    if (result.isConfirmed) {
      const [username, password] = result.value
      setLoginState(loginStates.loggingIn)
      try {
        const response = await services.auth.login({ username, password })
        if (response.data.accessToken) {
          Auth.setAccessToken(response.data.accessToken)
          history.push('/dashboard')
        } else {
          setLoginState(loginStates.failure)
        }
      } catch (err) {
        console.error(err)
        setLoginState(loginStates.failure)
      }
    }
  }

  useEffect(() => {
    if (loginState === loginStates.checkingIfLoggedInAlready) {
      if (Auth.isAuthenticated()) {
        history.push('/dashboard')
      } else {
        setLoginState(loginStates.notLoggedIn)
      }
    }
    if (loginState === loginStates.failure) {
      Swal.fire({
        titleText: 'Try Again',
        text: 'Sorry, your login credentials are incorrect.',
        type: 'warning'
      })
    }
  }, [loginState])

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        backgroundImage: 'url(/img/splash.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: 'white',
        textAlign: 'center'
      }}
    >
      <h1>Nasha I.T.</h1>
      <div
        style={{
          position: 'absolute',
          bottom: '5rem',
          width: '100%',
          textAlign: 'center'
        }}
      >
        {loginState === loginStates.notLoggedIn && (
          <Button variant="contained" size="large" onClick={loginClicked}>
            Login
          </Button>
        )}
        {loginState === loginStates.failure && (
          <Button variant="contained" size="large" onClick={loginClicked}>
            Try Again
          </Button>
        )}
        {loginState === loginStates.loggingIn && <CircularProgress />}
      </div>
    </div>
  )
}
