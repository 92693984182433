import { CircularProgress } from '@mui/material'
import React, { useState, useEffect, useRef } from 'react'
import 'github-markdown-css'

export default function Markdown({ text, style = {}, ...props }) {
  const [status, setStatus] = useState('loading')
  const [html, setHtml] = useState(null)
  const divRef = useRef(null)

  useEffect(() => {
    function onHashChange() {
      if (divRef.current) {
        const el = divRef.current.querySelector(`a.anchor[href='${window.location.hash}']`)
        if (el) {
          const offset = el.offsetTop
          divRef.current.scrollTop = offset
        } else if (window.location.hash === '') {
          divRef.current.scrollTop = 0
        }
      }
    }

    window.addEventListener('hashchange', onHashChange)
    return () => {
      window.removeEventListener('hashchange', onHashChange)
    }
  }, [])

  useEffect(() => {
    async function go() {
      const response = await fetch('https://api.github.com/markdown', {
        method: 'POST',
        headers: {
          Accept: 'application/vnd.github.v3+json'
        },
        body: JSON.stringify({ text })
      })
      const markup = await response.text()

      setHtml(markup)
      setStatus('complete')
    }
    go()
  }, [text])
  if (status === 'loading') {
    return <CircularProgress />
  } else {
    return (
      <div
        ref={divRef}
        style={{ minHeight: '100vh', maxWidth: 'calc(100vw - 120px)', padding: '1rem', ...style }}
        {...props}
        className="markdown-body"
        dangerouslySetInnerHTML={{ __html: html }}
      />
    )
  }
}
