import axios from 'axios'
import Swal from 'sweetalert2'
import * as Auth from '../helpers/auth'

export default function serviceFactory(options = {}) {
  const headers = options.headers || {}

  const service = axios.create({
    baseURL: (options && options.uri) || process.env.REACT_APP_BASE_API,
    timeout: (options && options.timeout) || 15000,
    headers
  })

  service.cacheGet = async (uri, duration) => {
    const value = localStorage.getItem(`cached-get:${uri}`)
    const exp = localStorage.getItem(`cached-exp:${uri}`)
    if (value && new Date() < new Date(exp)) {
      return JSON.parse(value)
    }
    const response = await service.get(uri)
    localStorage.setItem(`cached-get:${uri}`, JSON.stringify(response))
    localStorage.setItem(new Date().valueOf() + duration * 1000 * 60)
    return response
  }

  service.interceptors.request.use(async (config) => {
    if (!options.noHeaders) {
      if (Auth.isAuthenticated()) {
        config.headers.Authorization = `Bearer ${Auth.getAccessToken()}`
      }
      // config.headers['cache-control'] = 'no-cache'
    }
    return config
  })

  if (!options.noInterceptors)
    service.interceptors.response.use(
      (res) => {
        return res
      },
      async (error) => {
        if (error.response) {
          switch (error.response.status) {
            case 401:
              await Swal.fire({
                titleText: 'Session Expired',
                text: 'Your session has expired. you are being redirected to the login page.',
                type: 'warning'
              })
              return Promise.reject(error)
            case 403:
              await Swal.fire({
                titleText: 'Session Expired',
                text: 'Sorry, you are unauthorized to perform that task.',
                type: 'warning'
              })
              return Promise.reject(error)
            case 404:
            case 422:
            case 500:
            case 502:
            default:
              await Swal.fire({
                titleText: 'Sorry, and unexpected error occurred. If problem persists, please contact support.',
                text: error.response.data.message,
                type: 'warning'
              })
              return Promise.reject(error)
          }
        }
        return Promise.reject(error)
      }
    )
  return service
}
