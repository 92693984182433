import Swal from 'sweetalert2'
import services from '../services'
export function popup() {
  Swal.fire({
    title: 'Send A Comment',
    html: `
    <textarea id="swal-comment" maxlength="500" style="width: 100%; height: 5rem; padding:0.5rem" onchange="window.swalComment = this.value"></textarea>
    <br><br>
    <input type="checkbox" id="swal-anonymous" onchange="window.swalAnonymous = this.value"> Anonymous</input>`,
    confirmButtonText: 'Send'
  }).then(async (x) => {
    await services.comments.post({
      anonymous: !!window.swalAnonymous,
      sent: x.isConfirmed,
      text: window.swalComment
    })
    window.swalComment = ''
    window.swalAnonymous = false
  })
}
