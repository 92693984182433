import React, { useState } from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import MenuIcon from '@mui/icons-material/Menu'
import { Backdrop, Container } from '@mui/material'
import SpeedDial from '@mui/material/SpeedDial'
import SpeedDialIcon from '@mui/material/SpeedDialIcon'
import SpeedDialAction from '@mui/material/SpeedDialAction'
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined'
import LogoutIcon from '@mui/icons-material/Logout'
import CloseIcon from '@mui/icons-material/Close'
import { makeStyles } from '@mui/styles'
import { useHistory } from 'react-router'

const actions = [
  { icon: <FileCopyIcon />, name: 'Home', onClick: (history) => history.push('/dashboard') },
  {
    icon: <LogoutIcon />,
    name: 'Logout',
    onClick: (history) => {
      alert('logging out')
      history.push('/logout')
    }
  }
]

const useStyles = makeStyles({
  speedDial: {
    '&>.MuiFab-circular': {
      backgroundColor: 'rgba(0,0,0,0)',
      boxShadow: 'none',
      color: 'white',
      '&:hover': {
        backgroundColor: 'rgba(0,0,0,0)',
        boxShadow: 'none',
        color: 'white'
      }
    }
  },
  navTitle: {
    textIndent: '3rem'
  }
})

export default function MainTemplate({ children }) {
  const classes = useStyles()
  const history = useHistory()
  const [navOpen, setNavOpen] = useState(false)
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <Backdrop open={navOpen} />
            <SpeedDial
              className={classes.speedDial}
              ariaLabel="menu"
              direction="down"
              open={navOpen}
              onClick={() => setNavOpen(!navOpen)}
              onBlur={() => setTimeout(() => setNavOpen(false), 200)}
              sx={{ position: 'absolute', top: '0.25rem', left: '0' }}
              icon={<SpeedDialIcon style={{ borderRadius: 0 }} icon={<MenuIcon />} openIcon={<CloseIcon />} />}
            >
              {actions.map((action) => (
                <SpeedDialAction
                  key={action.name}
                  icon={action.icon}
                  tooltipTitle={action.name}
                  tooltipOpen
                  tooltipPlacement="right"
                  onClick={() => action.onClick(history)}
                />
              ))}
            </SpeedDial>
            {/* <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
              <MenuIcon />
            </IconButton> */}
            <Typography className={classes.navTitle} variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Nasha Tech Class
            </Typography>
            {/* <Button color="inherit">Login</Button> */}
          </Toolbar>
        </AppBar>
      </Box>
      <Container className="main-container">{children}</Container>
    </>
  )
}
