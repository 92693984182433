import serviceFactory from './service-factory'

export default {
  auth: {
    login: ({ username, password }) =>
      serviceFactory({ noInterceptors: true }).post('/student-login', { username, password })
  },
  students: {
    getAll: ({ classId }) => serviceFactory().get(`/classes/${classId}/students`)
  },
  assignments: {
    getAll: ({ classId }) => serviceFactory().get(`/classes/${classId}/assignments`),
    get: ({ classId, assignmentId }) => serviceFactory().get(`/classes/${classId}/assignments/${assignmentId}`)
  },
  teams: {
    post: (data) => serviceFactory().post(`/classes/${data.classId}/assignments/${data.assignmentId}/teams`, data),
    put: (data) => serviceFactory().put(`/classes/${data.classId}/assignments/${data.assignmentId}/teams/${data.id}`, data),
    getOwn: ({ classId, assignmentId }) => serviceFactory().get(`/classes/${classId}/assignments/${assignmentId}/teams`)
  },
  comments: {
    post: (data) => serviceFactory().post('/comments', data)
  }
}
